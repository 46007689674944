import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
const teamMembers = [
  {
    name: 'Amit Thakur',
    description: 'An NUS MBA graduate with over 18 years in full-stack application development, software solutions architecture, DevOps, and Big Data for large-scale enterprises. Expert in high availability, scalability, and security. Holds certifications in CKAD, PMP, AWS Solutions Architect, and AWS Big Data Specialty. Skilled in Java, Python, Jenkins CI/CD, Ansible, Docker, Kubernetes, AWS, Spark, Hadoop, and more.',
    imagePath: 'amit-thakur.jpg'
  },
  {
    name: 'Sumit Thakur',
    description: 'MS from California State University. Lead Mobile Engineer: 12+ years in Android App Design & Development, 5+ years in Kotlin. Proficient in Java, Android, Kotlin, SDLC, Architectural Patterns (MVC, MVP, MVVM), Agile (JIRA, Confluence), GIT, SourceTree, Zeplin , Sketch. Delivers high quality apps',
    imagePath: 'sumit-thakur.jpg'
  },
  {
    name: 'Sahil Thakur',
    description: 'Competent Digital Marketer with experience in Google Ads, SEO Marketing, Analytics, Google Tag Manager and Facebook content building. Adept at creating and implementing client centered, successful campaigns, aimed at improving brand awareness and presence.    ',
    imagePath: 'sahil-thakur.jpg'
  },
  {
    name: 'Mohinder Thakur',
    description: 'An accomplished Entrepreneur and Marketing Professional with over 25 years of experience in the publishing industry. Mohinder began his career at Ratna Sagar Publications, where he spent 9 years honing his skills. In 2004, he co-founded Cordova Publications, where he played a pivotal role in scaling the business into a prominent publishing company with a nationwide presence and international operations. Under his leadership, the company grew to employ over 800 people. He later founded Global Rise Publications, where he continues to demonstrate his ability to successfully grow businesses from the ground up, building a dynamic team and setting ambitious growth targets for the coming years.',
    imagePath: 'mohinder-thakur.jpg'
  },
  {
    name: 'Shivam Rana',
    description: 'Data Scientist and ML Engineer Proficient in Python and SQL, I have developed and implemented predictive models and conducted EDA. My expertise in data visualization and communication has driven business growth and provided valuable insights.    ',
    imagePath: 'shivam-rana.jpg'
  }
];
const AboutUs = () => {
  return (
    <Container fluid className="px-0">
      {/* Hero Section */}
      <Row className="min-h-200 align-items-center justify-content-center bg-light text-dark text-center py-5">
        <Col md={8}>
          <h1 className="display-4 font-weight-bold">About Us</h1>
          <p className="lead">Meet our dedicated team of experts who strive for excellence and innovation.</p>
        </Col>
      </Row>

      {/* Team Member Cards */}
      <Row className="justify-content-center py-5">
        {teamMembers.map((member, index) => (
          <Col md={6} lg={4} key={index} className="mb-4">
            <Card className="shadow-sm h-100">
              <Card.Img variant="top" src={member.imagePath} alt={member.name} />
              <Card.Body>
                <Card.Title className="text-dark font-weight-bold">{member.name}</Card.Title>
                <Card.Text className="text-muted">{member.description}</Card.Text>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default AboutUs;
