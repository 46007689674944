import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

function ExplainerVideo() {
  return (
    <Container>
      <Row className="mt-4">
        <Col>
          <h1>About StrideCal</h1>
          <p>Watch the video below to learn more about StrideCal!</p>
          <video controls width="100%">
            <source src="/stridecal_explainer.mov" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </Col>
      </Row>
    </Container>
  );
}

export default ExplainerVideo;
