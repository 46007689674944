import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';  // Import Bootstrap JS for accordion functionality
import './styles/App.css';

import Header from './components/Header';
import Footer from './components/Footer';
import HomePage from './containers/HomePage';
import ContactUs from './containers/ContactUs';
import Products from './containers/Products';
import AboutUs from './containers/AboutUs';
import Blog from './containers/Blog';
import Services from './containers/Services';
import ExplainerVideo from './containers/ExplainerVideo';

function App() {
  return (
    <Router>
      <header className="App-header">
        <div className="container"> {/* Align the navbar to match the content panel */}
          <Header />
        </div>
      </header>
      <div className="main-content-container">
        <div className="container-fluid d-flex justify-content-center">
          <div className="content-panel col-lg-8 col-md-10 col-sm-12">
            <Routes>
              <Route exact path="/" element={<HomePage />} />
              <Route exact path="/Products" element={<Products />} />
              <Route exact path="/ContactUs" element={<ContactUs />} />
              <Route exact path="/AboutUs" element={<AboutUs />} />
              <Route exact path="/Services" element={<Services />} />
              <Route exact path="/ExplainerVideo" element={<ExplainerVideo />} />
              <Route exact path="/Blog" element={<Blog />} />
            </Routes>
          </div>
        </div>
      </div>
      <footer className="App-footer">
        <div className="container"> {/* Align the footer to match the content panel */}
          <Footer />
        </div>
      </footer>
    </Router>
  );
}

export default App;
